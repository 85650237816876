import LogRocket from 'logrocket';

import { getCheckoutHost } from './env';

export const loadScript = async (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => resolve();
    script.onerror = () => {
      const error = 'Failed to load KYC script';
      LogRocket.captureMessage(error);
      reject(error);
    };
    script.src = src;
    document.head.appendChild(script);
  });

export const loadKycScript = async () => {
  const host = await getCheckoutHost();

  let identity = `https://identity.${host}/dist/identity.min.js`;

  await loadScript(identity);
};
