import { createAppleWebPaymentSession } from 'app/api';
import { PayPlanParameters, PayToSendAgreementRequest, payToSendAgreement } from 'app/api.april';
import ErrorMessage from 'components/ErrorMessage';
import { PayPlanEligibilityText } from 'components/PayPlanSelection/styles';
import PayPlanSelectionStep from 'components/PayPlanSelectionStep';
import PaymentSource from 'components/PaymentSource';
import { DirectDebitFormValues, useDirectDebitForm } from 'components/PaymentSource/DirectDebitForm';
import { PayToFormValues, usePayToForm } from 'components/PaymentSource/PayToForm';
import PaymentMethod from 'components/PaymentSource/PaymentMethod';
import checkPayPlanAmountEligibility from 'lib/checkPayPlanAmountEligibility';
import { SpinnerWrapper } from 'lib/commonStyles';
import { toCurrency } from 'lib/currency';
import {
  PAYPLAN_INCOMPLETE_ERROR_MESSAGE,
  STATE_ERROR_MESSAGE,
  SURCHARGE_REQUIRED_ERROR_MESSAGE,
  getErrorMessage,
} from 'lib/errorMessages';
import {
  useApplePaySubmit,
  useDirectDebitSubmit,
  useError,
  useGooglePaySubmit,
  useMessageCallback,
  usePayCardSubmit,
  useQuerySavedCards,
  useSendMessage,
  useShowB2bSaveCard,
  useSubmitMessageCallback,
} from 'lib/hooks';
import { ApplePayMessage, EventName, toApplePayMessage, toEventMessage, toPaymentTokenMessage } from 'lib/messages';
import { ApplePayPaymentSource, GooglePayPaymentSource, PaymentType, SelectedPaymentSource, Step } from 'lib/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApplePayPaymentSource,
  setCheckoutStep,
  setPayToAgreementRequest,
  setPayToToken,
  setPaymentType,
  setSelectedPayPlanVariant,
  setSelectedPaymentSource,
} from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Spinner, Text } from '@limepayments/cosmic';

export const Divider = ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} {...props}>
    <div css={{ flex: 1, height: 1, backgroundColor: 'rgb(var(--lp-colors-neutral-300))' }} />
    <div css={{ padding: '0 16px', color: 'rgb(var(--lp-colors-neutral-600))' }}>
      <Text tagName="span" variant="body-3">
        {children}
      </Text>
    </div>
    <div css={{ flex: 1, height: 1, backgroundColor: 'rgb(var(--lp-colors-neutral-300))' }} />
  </div>
);

export interface PaymentSelectionStepType {}

const PaymentSelectionStep = (props: PaymentSelectionStepType) => {
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(true);
  const [isCardReady, setIsCardReady] = useState<boolean>(false);
  const [isCvcReady, setIsCvcReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [b2bSaveCardError, setB2bSaveCardError] = useState<boolean>(false);

  const {
    paymentType,
    config,
    params,
    customerId,
    cardPaymentSources,
    applePayPaymentSource,
    googlePayPaymentSource,
    b2bCardUsageScope,
    selectedPaymentSource,
    surchargeQuote,
    payPlanVariants,
    selectedPayPlanVariant,
  } = useSelector((state: ReduxState) => ({
    paymentType: state.paymentType,
    config: state.config,
    params: state.params,
    customerId: state.customerId,
    cardPaymentSources: state.cardPaymentSources,
    applePayPaymentSource: state.applePayPaymentSource,
    googlePayPaymentSource: state.googlePayPaymentSource,
    b2bCardUsageScope: state.b2bCardUsageScope,
    selectedPaymentSource: state.selectedPaymentSource,
    surchargeQuote: state.surchargeQuote,
    payPlanVariants: state.payPlanVariants,
    selectedPayPlanVariant: state.selectedPayPlanVariant,
  }));

  const showB2bSaveCard = useShowB2bSaveCard();
  const surchargeQuoteRequired = useMemo(() => config?.surchargeQuoteRequired, [config]);

  useEffect(() => {
    const enabled =
      (!surchargeQuoteRequired || !!surchargeQuote) &&
      ((selectedPaymentSource === SelectedPaymentSource.newCard && isCardReady) ||
        (selectedPaymentSource === SelectedPaymentSource.savedCard && isCvcReady) ||
        selectedPaymentSource === SelectedPaymentSource.applePay ||
        selectedPaymentSource === SelectedPaymentSource.googlePay ||
        selectedPaymentSource === SelectedPaymentSource.directDebit ||
        selectedPaymentSource === SelectedPaymentSource.payTo);
    setDisabledSubmitBtn(loading || !enabled);
  }, [isCardReady, isCvcReady, loading, selectedPaymentSource, surchargeQuote, surchargeQuoteRequired]);

  const dispatch = useDispatch();
  const onPayCardSubmit = usePayCardSubmit();
  const onError = useError();
  const sendMessage = useSendMessage();
  const onQuerySavedCards = useQuerySavedCards();
  const onApplePaySubmit = useApplePaySubmit();
  const onGooglePaySubmit = useGooglePaySubmit();
  const onDirectDebitSubmit = useDirectDebitSubmit();

  const {
    minPayPlanAmount,
    maxPayPlanAmount,
    minPayPlanAmountEligibility,
    maxPayPlanAmountEligibility,
    payPlanAmountEligibility,
  } = useMemo(() => checkPayPlanAmountEligibility(params, config), [params, config]);

  const hidePayPlan = useMemo(
    () => !!params?.hidePayLaterOption || (payPlanVariants && !payPlanVariants?.length),
    [params, payPlanVariants],
  );

  const hidePayCard = useMemo(() => !!config?.allowHideFullPay && !!params?.hideFullPayOption, [config, params]);

  useEffect(() => {
    hidePayCard && dispatch(setPaymentType('payplan'));
    hidePayPlan && dispatch(setPaymentType('paycard'));
  }, [hidePayCard, hidePayPlan, dispatch]);

  useEffect(() => {
    setErrorMessage('');
  }, [selectedPaymentSource, paymentType]);

  /**
   * if query saved cards failed, will not display error message
   * user can still continue to pay by new credit card details
   */
  useEffect(() => {
    const fetchSavedCards = async () => {
      try {
        if (!customerId) {
          return;
        }
        setLoading(true);
        await onQuerySavedCards();
      } catch (e) {
        onError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedCards();
  }, [customerId, onError, onQuerySavedCards]);

  const handlePaymentTypeChanged = useCallback(
    (paymentType: PaymentType) => {
      dispatch(setPaymentType(paymentType));
      paymentType === 'payplan' && dispatch(setSelectedPaymentSource(null));
      sendMessage(
        toEventMessage(EventName.ToggledPaymentType, params?.elementId || '', {
          paymentType,
          payPlanVariant: paymentType === 'payplan' ? selectedPayPlanVariant?.payPlanVariant ?? null : undefined,
        }),
      );
    },
    [dispatch, params?.elementId, selectedPayPlanVariant, sendMessage],
  );

  const handlePayPlanVariantChanged = useCallback(
    (payPlanVariant: PayPlanParameters) => {
      dispatch(setSelectedPayPlanVariant(payPlanVariant));
      sendMessage(
        toEventMessage(EventName.ToggledPaymentType, params?.elementId || '', {
          paymentType: 'payplan',
          payPlanVariant: payPlanVariant.payPlanVariant,
        }),
      );
    },
    [dispatch, params, sendMessage],
  );

  const handlePaymentSourceChanged = useCallback(
    (paymentSource: SelectedPaymentSource | null) => {
      dispatch(setSelectedPaymentSource(paymentSource));
      if (paymentSource && paymentType === 'payplan') {
        handlePaymentTypeChanged('paycard');
      }
    },
    [dispatch, handlePaymentTypeChanged, paymentType],
  );

  const handleApplePaySubmit = useCallback(
    async (applePayPaymentSource: ApplePayPaymentSource) => {
      if (!params) throw Error(STATE_ERROR_MESSAGE);
      if (params.preventWalletSubmit || surchargeQuoteRequired) return;

      setLoading(true);
      setErrorMessage('');
      try {
        const { paymentTokenId } = await onApplePaySubmit(applePayPaymentSource);
        paymentTokenId &&
          sendMessage(
            toPaymentTokenMessage(
              paymentTokenId,
              {
                amount: params.paycardAmount,
                currency: params.currency,
                paymentType: 'paycard',
                payType: 'PayInFull',
                paymentMethodType: 'NetworkToken',
              },
              params.elementId,
            ),
          );
      } catch (e) {
        setErrorMessage(onError(e));
      } finally {
        setLoading(false);
      }
    },
    [params, surchargeQuoteRequired, sendMessage, onApplePaySubmit, onError],
  );

  const handleGooglePaySubmit = useCallback(
    async (googlePayPaymentSource: GooglePayPaymentSource) => {
      if (!params) throw Error(STATE_ERROR_MESSAGE);
      if (params.preventWalletSubmit || surchargeQuoteRequired) return;

      setLoading(true);
      setErrorMessage('');
      try {
        const { paymentTokenId } = await onGooglePaySubmit(googlePayPaymentSource);
        paymentTokenId &&
          sendMessage(
            toPaymentTokenMessage(
              paymentTokenId,
              {
                amount: params.paycardAmount,
                currency: params.currency,
                paymentType: 'paycard',
                payType: 'PayInFull',
                paymentMethodType: 'NetworkToken',
              },
              params.elementId,
            ),
          );
      } catch (e) {
        setErrorMessage(onError(e));
      } finally {
        setLoading(false);
      }
    },
    [params, surchargeQuoteRequired, sendMessage, onGooglePaySubmit, onError],
  );

  const handleDirectDebitSubmit = useCallback(
    async (values: DirectDebitFormValues) => {
      setErrorMessage('');
      setLoading(true);

      try {
        if (!params) throw Error(STATE_ERROR_MESSAGE);

        const { paymentTokenId } = await onDirectDebitSubmit(values);

        paymentTokenId &&
          sendMessage(
            toPaymentTokenMessage(
              paymentTokenId,
              {
                amount: params.paycardAmount,
                currency: params.currency,
                paymentType: 'paycard',
                payType: 'PayInFull',
                paymentMethodType: 'DirectDebit',
              },
              params.elementId,
            ),
          );
      } catch (error) {
        setErrorMessage(onError(error));
      } finally {
        setLoading(false);
      }
    },
    [onDirectDebitSubmit, onError, params, sendMessage],
  );

  const directDebitForm = useDirectDebitForm({
    onSubmit: handleDirectDebitSubmit,
  });

  const handlePayToSubmit = useCallback(
    async ({ identifierType, customerName, aliasType, aliasValue, bsb, accountNumber }: PayToFormValues) => {
      setErrorMessage('');
      setLoading(true);

      try {
        if (!params) throw Error(STATE_ERROR_MESSAGE);

        const payToAgreementRequest: PayToSendAgreementRequest = {
          customerName,
          customerAccIdentifier:
            identifierType === 'Alias'
              ? {
                  Alias: {
                    aliasType,
                    value: aliasValue,
                  },
                }
              : {
                  Bban: {
                    value: `${bsb}-${accountNumber}`,
                  },
                },
          terms: {
            AgreementRequestTermsOneOff: {
              amount: {
                amount: surchargeQuote ? surchargeQuote.totalAmount : params.paycardAmount,
                currency: params.currency,
              },
            },
          },
        };

        const { token } = await payToSendAgreement(params.publicKey, payToAgreementRequest);

        dispatch(setPayToAgreementRequest(payToAgreementRequest));
        dispatch(setPayToToken(token));
        dispatch(setCheckoutStep(Step.PayTo));
      } catch (error) {
        setErrorMessage(onError(error));
      } finally {
        setLoading(false);
      }
    },
    [onError, params, surchargeQuote, dispatch],
  );

  const payToForm = usePayToForm({ onSubmit: handlePayToSubmit });

  const handleSubmitPay = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      if (!params || !config) throw Error(STATE_ERROR_MESSAGE);

      if (
        !selectedPaymentSource ||
        (selectedPaymentSource === SelectedPaymentSource.googlePay && !googlePayPaymentSource) ||
        (selectedPaymentSource === SelectedPaymentSource.applePay && !applePayPaymentSource)
      ) {
        throw Error('Please select a payment method');
      }
      if (selectedPaymentSource === SelectedPaymentSource.newCard && !isCardReady) {
        throw Error('Please enter valid credit card details');
      }
      if (selectedPaymentSource === SelectedPaymentSource.savedCard && !isCvcReady) {
        throw Error('Please enter valid CVC details');
      }
      if (showB2bSaveCard && SelectedPaymentSource.newCard === selectedPaymentSource) {
        if (!b2bCardUsageScope) {
          setB2bSaveCardError(true);
          return;
        }
        setB2bSaveCardError(false);
      }
      if (surchargeQuoteRequired && !surchargeQuote) {
        throw Error(SURCHARGE_REQUIRED_ERROR_MESSAGE);
      }
      if (selectedPaymentSource === SelectedPaymentSource.directDebit) {
        return directDebitForm.submitForm();
      }
      if (selectedPaymentSource === SelectedPaymentSource.payTo) {
        return payToForm.submitForm();
      }

      const { paymentTokenId, cardPaymentSource } = await onPayCardSubmit();

      paymentTokenId &&
        sendMessage(
          toPaymentTokenMessage(
            paymentTokenId,
            {
              amount: params.paycardAmount,
              currency: params.currency,
              paymentType: 'paycard',
              payType: 'PayInFull',
              paymentMethodType: 'Card',
              paymentSource: cardPaymentSource ? { cardPaymentSource } : undefined,
            },
            params.elementId,
          ),
        );
    } catch (e) {
      setErrorMessage(onError(e));
    } finally {
      setLoading(false);
    }
  }, [
    params,
    config,
    isCardReady,
    isCvcReady,
    showB2bSaveCard,
    selectedPaymentSource,
    googlePayPaymentSource,
    applePayPaymentSource,
    onPayCardSubmit,
    sendMessage,
    b2bCardUsageScope,
    onError,
    directDebitForm,
    payToForm,
    surchargeQuoteRequired,
    surchargeQuote,
  ]);

  const handleApplePayMessage = useCallback(
    async ({ applePayMessage }: ApplePayMessage) => {
      if (!params || !config) return;

      if (applePayMessage.event === 'onValidateMerchant') {
        try {
          const merchantSession = await createAppleWebPaymentSession(
            config.apiBaseUri,
            params.publicKey,
            config.merchantId,
            {
              validationURL: applePayMessage.payload.validationURL,
              displayName: config.merchantBusinessDisplayName,
              merchantDomain: applePayMessage.payload.merchantDomain,
            },
          );
          sendMessage(
            toApplePayMessage(
              {
                event: 'completeMerchantValidation',
                payload: merchantSession,
              },
              params.elementId,
            ),
          );
        } catch (e) {
          setErrorMessage(onError(e));
          sendMessage(
            toApplePayMessage(
              {
                event: 'abort',
                payload: getErrorMessage(e),
              },
              params.elementId,
            ),
          );
        }
      }
      if (applePayMessage.event === 'onPaymentAuthorized') {
        console.log('apple pay token:');
        console.log(JSON.stringify(applePayMessage.payload));
        dispatch(setApplePayPaymentSource(applePayMessage.payload));
        sendMessage(
          toApplePayMessage(
            {
              event: 'completePayment',
              payload: { status: ApplePaySession.STATUS_SUCCESS },
            },
            params.elementId,
          ),
        );
        handleApplePaySubmit(applePayMessage.payload);
      }
    },
    [params, config, sendMessage, handleApplePaySubmit, onError, dispatch],
  );

  const handleSubmitMessage = useCallback(
    () =>
      loading
        ? onError('Please wait, payment form is processing your request.')
        : paymentType === 'paycard'
        ? handleSubmitPay()
        : paymentType === 'payplan'
        ? onError(PAYPLAN_INCOMPLETE_ERROR_MESSAGE)
        : null,
    [loading, paymentType, handleSubmitPay, onError],
  );

  useSubmitMessageCallback(handleSubmitMessage);

  const onMessage = useCallback(
    (data: MessageEvent['data']) => {
      let message: any;

      try {
        message = JSON.parse(data);
      } catch (error) {}

      if (message?.service === 'Limepay') {
        message.applePayMessage && handleApplePayMessage(message as ApplePayMessage);
      }
    },
    [handleApplePayMessage],
  );

  useMessageCallback(onMessage);

  if (!params || !config) {
    return <></>;
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: 16, position: 'relative' }}>
      {loading && (
        <SpinnerWrapper>
          <Spinner variant="simple" isVisible />
        </SpinnerWrapper>
      )}
      {!hidePayPlan && payPlanAmountEligibility && (
        <PaymentMethod
          brand="pay-plan"
          label="Buy now, pay later"
          isOpen={paymentType === 'payplan'}
          hidePaymentTotal={true}
          testId="payPlan"
          onToogle={() => handlePaymentTypeChanged(paymentType === 'paycard' ? 'payplan' : 'paycard')}
        >
          <PayPlanSelectionStep onChange={handlePayPlanVariantChanged} />
        </PaymentMethod>
      )}
      {!hidePayPlan && !payPlanAmountEligibility && (
        <div tabIndex={0}>
          {!minPayPlanAmountEligibility && (
            <PayPlanEligibilityText variant="body-3" testId="minPayPlanAmountEligibility">
              Split the cost when you spend {toCurrency(minPayPlanAmount)} or more.
            </PayPlanEligibilityText>
          )}
          {!maxPayPlanAmountEligibility && (
            <PayPlanEligibilityText variant="body-3" testId="maxPayPlanAmountEligibility">
              Split the cost on purchases of up to {toCurrency(maxPayPlanAmount)}.
            </PayPlanEligibilityText>
          )}
        </div>
      )}
      {!hidePayPlan && payPlanAmountEligibility && !hidePayCard && <Divider>or</Divider>}
      {!hidePayCard && (
        <PaymentSource
          isActive={hidePayPlan || !payPlanAmountEligibility}
          savedCards={cardPaymentSources}
          showWallets={true}
          applePayPaymentSource={applePayPaymentSource}
          googlePayPaymentSource={googlePayPaymentSource}
          directDebitForm={directDebitForm}
          payToForm={payToForm}
          showB2bSaveCard={showB2bSaveCard}
          error={errorMessage}
          b2bSaveCardError={b2bSaveCardError}
          setIsCvcReady={setIsCvcReady}
          setIsCardReady={setIsCardReady}
          onGooglePaySubmit={handleGooglePaySubmit}
          submitDisabled={disabledSubmitBtn}
          onChange={handlePaymentSourceChanged}
          onSubmit={params.showPayNow ? handleSubmitPay : undefined}
        />
      )}
      {!selectedPaymentSource && <ErrorMessage messageBody={errorMessage} />}
    </div>
  );
};

export default PaymentSelectionStep;
